@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Change the white to any color */
.dark input:-webkit-autofill,
.dark input:-webkit-autofill:hover,
.dark input:-webkit-autofill:focus,
.dark input:-webkit-autofill:active{
	-webkit-box-shadow: 0 0 0 30px rgb(30 41 59) inset !important;
}

/* Change text in autofill textbox */
.dark input:-webkit-autofill {
	-webkit-text-fill-color: white !important;
}

/* Black text in RichTextEditor */
.dark .DraftEditor-editorContainer {
	color: #000 !important;
}

.rdt_Table,
.rdt_TableHeadRow,
.rdt_TableRow,
.rdt_Pagination {
	background: transparent !important;
}

.dark .rdt_Table,
.dark .rdt_TableHeadRow,
.dark .rdt_TableRow,
.dark .rdt_Pagination,
.dark .rdt_TableCol {
	color: white;
}

.dark .rdt_ExpanderRow {
	background-color: transparent;
}

.rdt_TableCol,
.rdt_TableCol_Sortable {
	font-weight: bold;
	font-size: 1.1em;
}

.rdt_TableRow_Clickable .rdt_TableRow {
	cursor: pointer;
}

.rdt_TableRow_Clickable .rdt_TableRow:hover {
	background-color: #f5f5f5;
}

/**
 * ==============================================
 * Dot Pulse
 * ==============================================
 */
.dot-pulse {
    position: relative;
    left: -9999px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #6a6a6a;
    color: #6a6a6a;
    box-shadow: 9999px 0 0 -5px;
    animation: dot-pulse 1.5s infinite linear;
    animation-delay: 0.25s;
}
.dot-pulse::before, .dot-pulse::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #6a6a6a;
    color: #6a6a6a;
}
.dot-pulse::before {
    box-shadow: 9984px 0 0 -5px;
    animation: dot-pulse-before 1.5s infinite linear;
    animation-delay: 0s;
}
.dot-pulse::after {
    box-shadow: 10014px 0 0 -5px;
    animation: dot-pulse-after 1.5s infinite linear;
    animation-delay: 0.5s;
}

@keyframes dot-pulse-before {
    0% {
        box-shadow: 9984px 0 0 -5px;
    }
    30% {
        box-shadow: 9984px 0 0 2px;
    }
    60%, 100% {
        box-shadow: 9984px 0 0 -5px;
    }
}
@keyframes dot-pulse {
    0% {
        box-shadow: 9999px 0 0 -5px;
    }
    30% {
        box-shadow: 9999px 0 0 2px;
    }
    60%, 100% {
        box-shadow: 9999px 0 0 -5px;
    }
}
@keyframes dot-pulse-after {
    0% {
        box-shadow: 10014px 0 0 -5px;
    }
    30% {
        box-shadow: 10014px 0 0 2px;
    }
    60%, 100% {
        box-shadow: 10014px 0 0 -5px;
    }
}
